@font-face {
  font-family:'primary';
  font-display:swap;
  font-style:normal;
  font-weight:normal;
  src:url('../res/fonts/Raleway-Thin.woff') format('woff');
}

@font-face {
  font-family:'secondary';
  font-display:swap;
  font-style:normal;
  font-weight:normal;
  src:url('../res/fonts/AlegreyaSans-Regular.woff') format('woff');
}

@font-face {
  font-family:'third';
  font-display:swap;
  font-style:normal;
  font-weight:normal;
  src:url('../res/fonts/Myriad-Pro-Regular.woff') format('woff');
}
