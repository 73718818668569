@import "animations.css";
@import "fonts.css";
@import url("https://use.typekit.net/zup0haq.css");
@import url("https://cdn.plyr.io/3.7.2/plyr.css");

/* :: */
:root {
  --primary: #BBF1F1;
  --primary-hover-2: #008080;
  --primary-hover-3: #59debc;
  --p:translate(0,0);
}

::selection {
  color: white;
  background: var(--primary);
}
/* / :: */

/* ELEMENTS */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 2.5em;
}

body {
  font-family: third;
  font-weight: 100;
  margin: 0;
}

hr {
  background: rgba(150, 150, 150, .3);
  border: none;
  bottom: 0;
  height: 1.33px;
  position: absolute;
  width: 100%;
}

a {
  color: #FFF;
  text-decoration: none;
}
/* / ELEMENTS */

/* SPLASH */
.splash-ctnr {
  position:relative;
}

.splash-txt-ctnr {
  color: white;
  left: 11%;
  position: absolute;
  top: 30%;
}

.splash-txt {
  padding-bottom: .5em;
  filter: drop-shadow(0 0 .1em rgba(0, 0, 0, .5));
  font-family: myriad-pro;
  font-size: 3.12em;
  font-weight: 800;
}

.splash-txt-small {
  padding-bottom: 2em;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, .7));
  font-family: secondary;
  font-size: 1.62em;
  font-weight: 100;
  padding-right:4em;
}

.btn {
  background: white;
  border-bottom: 3px solid white;
  border-radius: 4px;
  color: #111;
  cursor: pointer;
  display:inline-block;
  font-size: 1.125em;
  padding: .9em 1.33em .9em 1.33em;
  transition: .7s;
  user-select: none;
}

.btn:hover {
  border-bottom: 3px solid var(--primary-hover-2);
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, .87));
  transform: translateY(-3px);
}

.about-btn {
  background:var(--primary-hover-2);
  border-bottom: 2px solid white;
  border-radius: 4px;
  color:white;
  font-size: 1.125em;
  padding: .9em 1.33em .9em 1.33em;
  transition: .5s;
  user-select: none;
}

.about-btn:hover {
  border-bottom: 2px solid #444;
  color:var(white);
  filter: drop-shadow(0 0 7px rgba(0, 0, 0, .2));
  transform: translateY(-2px);
}

#header-video {
  filter: brightness(0.7);
  position: relative;
  width: 100%;
  z-index: -1;
}

.mute-btn {
  color: rgb(220, 220, 220);
  font-size: 1.2em;
  padding: 0.5em;
  position: absolute;
  right: 0;
  text-align: center;
  transition: color .3s, font-size .3s;
  z-index: 900;
  top:100px;
}

.mute-btn:hover {
  color: white;
}

.down-arrow {
  position: absolute;
  bottom: 0.25em;
  font-size: 2.25em;
  transform: translateX(50vw);
  width: 50px;
  text-align: center;
  bottom:4.33em;
  display:none !important;
}

.down-arrow i {
  transition:.4s;
  color:rgb(240, 240, 240);
}

.down-arrow i:hover {
  transform:scale(1.18) translateY(2px);
  color:white;
}

/* / SPLASH */

/* CONTAINERS */
.content {
  background: #FFF;
  color: #111;
  position: relative;
  padding-left: 11em;
  padding-right: 11em;
}

#about {
  scroll-padding-top:200px;
}

.ctnr {
  min-height: fit-content;
  padding: 4em 0em 4em 0em;
  position: relative;
}

.ctnr h1 {
  filter: drop-shadow(1.5px 1.25px 0px rgba(11, 11, 11, .15));
  font-family: third;
  font-size: 44px;
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: .75em;
  user-select: none;
}

.ctnr h3 {
  filter: drop-shadow(.7px .4px 0px rgba(11, 11, 11, .15));
  font-family: third;
  font-size: 33px;
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: .75em;
  user-select: none;
}

.ctnr h2 {
  color: #666666;
  font-size: 27px;
}

.ctnr p {
  font-size: 22px;
  line-height: 1.5em;
  font-weight: 100;
  font-family: secondary;
}

.ctnr-main {
  position: absolute;
  top: 48px;
}

.ctnr-work {
  background: rgb(245, 245, 245);
}

.ctnr-footer {
  background: none;
  color: #CCC;
  font-size: 18px;
  margin-top: 1.5em;
  padding: 0;
  min-height: 0;
  padding-bottom: 1.25em;
  position: relative;
  text-align: center;
  width: 100%;
  line-height: 1.6em;
}
/* / CONTAINERS */

/* NAVBAR */
#navbar {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1001;
  border-bottom:2px solid #EEE;
  position:fixed;
  width:100%;
  transition:.2s;
  opacity:1;
  transition-behavior:allow-discrete;
}

.navbar img {
  height: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav-left {
  display: inline-block;
  padding-left: 175px;
}

.nav-right {
  display: inline-block;
  position: absolute;
  right: 0;
  padding-right: 175px;
}

.nav-item {
  display: inline-block;
  padding-left: 2em;
  padding-top: 34px;
  overflow:hidden;
}

.nav-item i {
  font-size: 25px;
  transition: .4s;
}

.nav-item i:hover {
  color: var(--primary-hover-3);
  transform:translateY(-1.5px);
}

.nav-item .social-svg-icon {
  transition:.4s !important;
  fill:black;
}

.nav-item a:hover {
  transition:.4s;
}

.nav-item a:hover ~ .social-svg-icon {
  fill: var(--primary-hover-3);
}

.nav-item a:hover {
  transform:translateY(-3px);
}

.social-svg-icon:hover {
  fill: var(--primary-hover-3);
  /* transform:translateY(-3px); */
}

.nav-item a {
  color: unset;
}
/* / NAVBAR */

/* ABOUT */
.about img {
  width: 215px;
  float: left;
  margin-right: 2em;
  border-radius: 7px;
}

.about p {
  transform: translateY(-4px);
  line-height: 1.6em;
}

.about:after {
  content: "";
  clear: both;
  display: table;
}
/* / ABOUT */

/* SHOWCASE */
.work-box {
  display: block;
  width: inherit;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
}

.work-item {
  position: relative;
  display: block;
  min-height: fit-content;
  display: inline-block;
  padding-bottom: 2em;
}

.work-box img {
  width: inherit;
  object-fit: cover;
  transition: .5s;
}

.work-content {
  position: relative;
  text-align: center;
  font-family: secondary;
}

.work .project-title {
  font-size: 1.33rem;
  font-weight: bold;
  /* margin-bottom: 0; */
}

.work-content .description {
  font-size: 1.2rem;
  margin-top: .25em;
  font-weight: 100;
}

.description:before {
  content: "(";
}

.description:after {
  content: ")";
}

.grid-container {
  padding-top:1em;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  padding-bottom: 1em;
  overflow:hidden;
  padding-right:1em;
  display:inline-block;
  width:29.75%;
  position:relative;
}

.grid-item .img {
  position:relative;
  height:fit-content;
  width:fit-content;
}

.item-ctnr {
  height: fit-content;
  margin: 0 auto;
  margin-left: 0;
  position: relative;
  width: fit-content;
}

.grid-item img {
  width: 100%;
  transition:.6s;
  height:auto;
}

.grid-item img:hover {
  transform: scale(1.04);
  cursor: pointer;
  user-select: none;
  filter: brightness(33%);
}

.grid-item-photo img:hover {
  transform: scale(1.04);
  cursor: pointer;
  user-select: none;
  filter: brightness(80%);
}

.grid-item img:hover +.play-btn {
  color:white !important;
}

.play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  color:transparent;
  font-size:40px;

  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);

  pointer-events:none;
}

p.play-btn {
  font-family: secondary;
  font-weight: 100;
  filter: drop-shadow(0 0 2px rgba(100, 100, 100, .5));
  font-size: 32px;
}
/* / SHOWCASE */

/* CONTACT */
.form-container {
  margin-top: 1.5em;
  width:fit-content;
}

.form-container textarea {
  height: 7em;
  background: rgb(245, 245, 245);
  margin-bottom: 2.5em;
  box-sizing: border-box;
  padding-left: .5em;
  background: white;
  width: 730px;
  border-bottom: solid 1px white;
}

.form-container .btn {
  padding: 0;
}

.form-container .btn:hover {
  filter: drop-shadow(0 0 7px rgba(100, 100, 100, 0.1));
  transform: translateY(-2px);
  color: var(--primary-hover-3);
}

.forms {
  display: block;
  color: grey;
  font-family: secondary;
  font-size: 22px;
  font-weight: 100;
  border: none;
  border-bottom: solid 1px grey;
  padding: 0.5em 0 0.5em 0;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
  transition: .3s;
  caret-color: #111;
}

input.forms.input {
  display: inline-block;
  margin-bottom: 30px;
  margin-right: 43px;
  width: 340px;
  background: rgb(245, 245, 245);
  box-sizing: border-box;
  padding-left: .5em;
  background: white;
  border-bottom: solid 1px white;
}

.forms:hover, input.forms.input:hover {
  border-color: var(--primary-hover-2);
  transform: translateY(-2px);
  filter: drop-shadow(0 0 7px rgba(100, 100, 100, 0.1));
}

.forms::placeholder {
  transition: .4s;
}

.forms:focus::placeholder {
  font-size: .7em;
}

.forms:focus {
  border-bottom: 1px solid rgb(245, 245, 245);
  box-shadow: 0 4px 0px var(--primary-hover-2);
  outline: none;
  transform: translateY(-2px);
  filter: drop-shadow(0 0 7px rgba(100, 100, 100, 0.1));
}

.submit {
  color:#333;
  border: none;
  background: none;
  font-family: third;
  font-size: 18px;
  user-select: none;
  padding: 1em 1.5em 1em 1.5em;
  border-bottom: 2px solid white;
  transition: .4s;
}

.submit:hover {
  cursor: pointer;
}
/* / CONTACT */

/* MODAL */
#modal, #modal-photo {
  position:fixed;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:2000;
  /* background:black; */
  background:transparent;
  width:75%;
}

#modal {
  /* top:calc((100vh / 2) + (100vh / 16)); */
}

.modal-x {
  position:absolute;
  top:0;
  right:0;
  width:max-content;
  height:max-content;
  z-index:2000;
  border-radius:50%;
  margin:.3em;
}

.modal-x:hover > i {
  color:rgb(255, 95, 95);
  cursor:pointer;
}

.modal-x i {
  color:white;
  filter:drop-shadow(0 0 4px rgba(0,0,0,.7));
  font-size:27px;
  z-index:2001;
  transform:translateX(-1px);
  transition:.2s;
}

#overlay, 
#overlay-photo,
#overlay-pdf {
  animation-name:overlay;
  animation-duration:.25s;
  animation-timing-function:ease-out;
  animation-fill-mode:forwards;
  position: fixed;
  display: none;
  z-index:999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.75);
  transition:.4s;
  opacity:0;
  
}

#overlay-photo {
  animation-name:overlay-photo !important;
  /* animation-duration:0s !important; */
}

#overlay-photo-2 {
  animation-name:overlay-photo-2 !important;
  /* animation-duration:0s !important; */
}

#overlay-pdf {
  animation-name:overlay-pdf !important;

}

#overlay-pdf-2 {
  animation-name:overlay-pdf-2 !important;

}

#overlay-2,
#overlay-photo-2,
#overlay-pdf-2 {
  animation-name:overlay-2;
  animation-duration:.25s;
  animation-timing-function:ease-out;
  animation-fill-mode:forwards;
  position:fixed;
  z-index:1000;
  width:100%;
  height:100%;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
/* / MODAL */

/* FOOTER */
.footer-2 {
  font-size: 14px;
}
.footer-2 a {
  color:rgba(190,190,200);
}
/* / FOOTER */


/* MOBILE-ONLY STUFF */
.mobile-only-third-paragraph {
  display:none;
}
.desktop-only-second-paragraph {
  display:inline;
}

.mobile-only-breakpoint {
  padding-top:.5em;
  display:none;
}
/* / MOBILE-ONLY STUFF */

.preview-video {
  filter: brightness(0.7);
  position: relative;
  width: 100%;
  z-index: -1;
}

.img {
  pointer-events:all;
}

.preview {
  position:unset !important;
  display:none;
}
.thumbnail {
  display:inline-block;
}

.work-header {
  color:#111;
  transition:.25s;
  border-bottom:solid 2px transparent;
}
.work-header:hover {
  color:var(--primary-hover-3);
  /* border-bottom:solid 2px var(--primary-hover-3); */
  filter: unset;
}
.work-header-active {
  color:var(--primary-hover-2);
  border-bottom:solid 2px var(--primary-hover-2);
}

.hidden {
  display:none;
}

#video-grid,
#photo-grid {
  animation-name:overlay;
  animation-duration:.5s;
  animation-delay:.3s;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards;
  opacity:0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

#overlay-photo-2 {
  position:relative;
}

.thumbnails-bar {
  /* border:2px solid black; */
  white-space:nowrap;
  overflow-x:auto;
  max-width:fit-content;
  margin:0 auto;
  /* background:rgba(20,20,20,.4); */
}

.arrow {
  cursor: pointer;
  color:white;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  font-size:1.75rem;
  text-shadow: 0 0 4px black;
  transition:.2s;
}

.arrow:hover {
  color:#63fdf3;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
  }

.thumbnails-bar::-webkit-scrollbar {
  width: 8px;
  height:10px;
}

.thumbnails-bar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.thumbnails-bar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--primary-hover-3);
}

/* Firefox scrollbar */
.thumbnails-bar {
  /* scrollbar-color: var(--primary-hover-3) #e7e7e7; */
  /* scrollbar-width:8px; */
}

#modal-pdf {
  width:80%;
  height:80vh;
  left:50%;
  right:50%;
  top:50%;
  transform:translate(-50%,-50%);
  position:absolute;
}

#resume-frame {
  width:100%;
  height:100%;
  /* width:80%; */
  /* height:80vh; */
  /* left:50%; */
  /* right:50%; */
  /* top:50%; */
  /* transform:translate(-50%,-50%); */
  /* position:absolute; */
}

.project-grid {
  box-sizing:border-box;
  padding:0 !important;
  grid-template-columns:repeat(3, 1fr);
}

.project-title {
  padding-bottom:0 !important;
}

.project-grid {
  display: grid;
  gap: 2rem;
  row-gap:2.2rem;
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  transition:.45s;
}

.thumbnail-container:hover {
  cursor: pointer;
  user-select: none;
  filter: brightness(75%);
}

.thumbnail-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height:100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  transition:.5s;
}

.thumbnail-container:hover img {
  width:102%;
  height:102%;
}

.work .project-title {
  padding-top:.5em;
  font-weight:bold;
  font-size: 1.5em;
  margin: 0.5rem 0;
  line-height: 1.4;
  overflow-wrap: break-word;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}

.project-role {
  font-size: 1em;
  color: #666;
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (max-width: 600px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.thumbnails-holder {
  width:120px;
  box-sizing:border-box;
  transition:.3s;
  position: relative;
  
  overflow: hidden;
  white-space: nowrap;

  /* GALLERY THUMBNAIL VARIANT */
  border:3px solid transparent; 
  padding-top: 56.25%;
  padding-top: 66.666%;
  /* padding-top: 75%; */
  
  border-color:white;
  border-color:#AAA;
  border-color:grey;
}

.thumbnails-ctnr {
  display:inline-block;
}

.thumbnails-holder:hover {
  /* border:3px solid var(--primary-hover-2); */
}

.thumbnails-padded {
  margin-right:.5rem;
}

.thumbnails-selected {
  border:3px solid var(--primary-hover-3);
  transition:.3s;
}

.work .project-title {
  margin-bottom:0;
}

.work .project-role {
  margin-top:0;
}

.project-role:before {
  content: "(";
}

.project-role:after {
  content: ")";
}

.modal-gallery {
  max-width:inherit;
}

#photo-ctnr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 100vw; 
  max-height: 100vh; 
  margin: 0 auto;
  background: rgba(0, 0, 0, .6);
  box-sizing: border-box;
}

.img-ctnr {
  position: relative;
  width: 100%;
  padding-top: 66.666%;
  overflow: hidden;
}

#photograph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#photo-controls {

}

.thumbnails {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover;
}


/* Move to responsive.css */
/* ADD VIDEO CONTAINER queries */
/* Fix for ultrawide (.49 & .35 ratio, 900px height, 600 px height, 2.5+ ppi) */
@media (max-width: 1600px) {
  #photo-ctnr {
    width: 70%;
    height: auto;
  }
  #modal {
    width:70%;
  }
}

@media (width < 1200px) and (height > 600px) {
  #photo-ctnr {
    width: 100%;
    height: auto;
  }
  #modal {
    width:100%;;
  }
}

/* ULTRAWIDE */
@media (width > 1920px) and (height < 1000px) {
  #photo-ctnr {
    width:42.5%;
  }
  #modal {
    width:42.5%;
  }
}

/* PHONE LANDSCAPE ORIENTATIONS */
@media (width > 600px) and (height < 450px) {
  #photo-ctnr {
    width:50%;
  }
  #modal {
    width:50%;
  }
}

@media (width > 800px) and (height < 400px) { /* Galaxy Fold Continuous */ 
  #photo-ctnr {
    width:42.5%;
  }
  #modal {
    width:42.5;
  }
}

@media (width > 800px) and (height < 700px) and (height > 600px) { /* Galaxy Fold Folded */
  #photo-ctnr {
    width:80%;
  }
  #modal {
    width:80%;
  }
}



/* GALLERY THUMBNAIL VARIANTS */
#photo-ctnr {
  /* background:#666;
  background:#AAA;
  background:rgb(200,200,200, .5); */
  
}
.img-ctnr {
  padding-top:66.666%;
  /* padding-top:56.25%; */
}

.thumbnails {
  object-fit:contain;
  height:100%;
}

.modal-gallery {
  border-top:solid 1px #777;
  padding-top:.33rem;
  /* background:#AAA;
  background:#666666;
  background:rgb(75, 75, 75, .6); */
}

.thumbnails-holder img {
  transition:.3s;
}
.thumbnails-selected {
  background:var(--primary-hover-2);
}

.thumbnails-holder {
}

.thumbnails-holder:hover img {
  filter:brightness(130%);
}

.thumbnail-ctnr-photos {
  padding-top:66.666% !important; /* 3:2 aspect ratio */
}

#photovideo {
  display:inline-block;
  position:relative;

}

#photo, #video {
  position:absolute;
  left:0;
  top:0;
}

