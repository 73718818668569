@keyframes overlay {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@keyframes overlay-photo {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@keyframes overlay-pdf {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}


@keyframes overlay-2 {
	from {
    transform:scale(0.75);
    transform:scale(0.75) translateY(40px);
    transform:translateY(40px);
    opacity:0;
	}
	to {
    transform:scale(1);
    transform:scale(1) translateY(-40px);
    transform:translateY(-40px);
    opacity:1;
	}
}

@keyframes overlay-photo-2 {
	from {
    /* transform:scale(0.75); */
    /* transform:scale(0.75) translateY(40px); */
    /* transform:translateY(40px); */
    opacity:0;
	}
	to {
    /* transform:scale(1); */
    /* transform:scale(1) translateY(-40px); */
    /* transform:translateY(-40px); */
    opacity:1;
	}
}

  @keyframes overlay-pdf-2 {
    from {
      /* transform:scale(0.75); */
      /* transform:scale(0.75) translateY(40px); */
      /* transform:translateY(40px); */
      opacity:0;
    }
    to {
      /* transform:scale(1); */
      /* transform:scale(1) translateY(-40px); */
      /* transform:translateY(-40px); */
      opacity:1;
    }
}