@import "style.css";
@media (max-width:1700px) {
  .down-arrow {
    bottom:.25em;
  }
  .about img {
    margin-bottom:1em;
  }

  .desktop-only-second-paragraph {
    display:none;
  }
  .mobile-only-third-paragraph {
    display:inline;
  }
  
}
@media (max-width:1368px) {
  .thumbnails-holder {
    width:110px;
  }
  #photo-container {
    width:80%;
  }

  .about img {
    width:200px;
  }

  .ctnr p {
    font-size:21px;
  }

  .work .project-title {
    font-size:1.15rem;
  }

  
}
@media (max-width:1270px) {

  .nav-left {
    padding-left:11%;
  }
  .nav-right {
    padding-right:11%;
  }

  .content {
    padding-left:8.5em;
    padding-right:8.5em;
  }

  .splash-txt-small {
    font-size:1.4em;
    padding-bottom:1.5em;
  }

  #work {
    /* padding-top:3em; */
  }
  
}
@media (max-width:1200px) {
  .content {
    padding-left:6em;
    padding-right:6em;
  }

  .ctnr h1 {
    font-size:36px;
  }
  
  .ctnr h3 {
    font-size:27px;
  }

  .splash-txt {
    font-size:2.6em;
  }

  .btn {
    font-size:16px;
  }

  .down-arrow {
    font-size:1.8em;
    bottom:.3em;
  }

  .ctnr p {
    font-size:20px;
  }

  .desktop-only-second-paragraph {
    display:none;
  }
  .mobile-only-third-paragraph {
    display:inline;
  }

  .about img {
    width:190px;
  }

  .submit {
    font-size:16px;
    padding-top:.9em;
    padding-bottom:.9em;
  }

  .forms {
    font-size:20px;
  }

  .ctnr-footer {
    font-size:16px;
  }

}
@media (max-width:1115px) {
  .work .project-title {
    font-size:1.1rem;
  }
  .work .project-role {
    font-size:1.05rem;
  }
  
  .grid-item {
    width:45%;
  }
  
  #navbar {
    position:sticky;
  }

  .splash-txt-small {
    font-size:1.25em;
  }

  .ctnr p {
    font-size:18px;
  }

  .submit {
    font-size:16px;
    padding-top:1em;
    padding-bottom:.9em;
  }

  .ctnr {
    padding-top:2em;
    padding-bottom:3em;
  }
  .forms {
    font-size:18px;
  }

  .ctnr-footer {
    font-size:14px;
  }

  .splash-txt-ctnr {
    top:15%;
  }

  #work {
    padding-bottom:1em;
  }

  .mute-btn {
    top:4px;
  }

}
@media (max-width:980px) {
  .thumbnails-holder {
    width:100px;
  }

  .form-container textarea {
    width:100%;
  }

  .splash-txt {
    font-size:2.15em;
  }

  .splash-txt-small  {
    font-size:18px;
  }

  .btn {
    font-size:.9em;
  }

  .down-arrow {
    bottom:.34em;
  }

  .ctnr h1 {
    font-size:2em;
  }

  .ctnr h3 {
    font-size:1.5em;
  }

  .ctnr p {
    font-size:17px;
  }

  .work .project-title {
    font-size:1rem;
  }

  .work .project-role {
    margin-top:.4em;
    font-size:.975rem;
  }

  .ctnr-footer {
    padding-bottom:1em;
    padding-top:0;
  }

  .about img {
    width:167px;
  }

  .navbar img {
    height: 45px;
    padding-top: 14px;
    padding-bottom: 13px;
  }

  .nav-item {
    padding-top: 23px;
  }

  .nav-item i {
    font-size: 25px;
  }

}
@media (max-width:845px) {
  .grid-item {
    width:44%;
  }

  .form-container {
    width:unset;
  }

  .input.forms.input {
    width:100%;
  }

  .down-arrow {
    display:none;
  }

}
@media (max-width:768px) {
  .arrow {
    font-size:1.5rem;
  }
  .thumbnails-holder {
    width:90px;
  }
  #photo-container {
    width:100%;
  }
  .grid-item {
    width:44%;
  }

  .about img {
    width:150px;
  }

  .content {
    padding-left:4em;
    padding-right:4em;
  }

  .ctnr {
    padding-top:2em;
    padding-bottom:3em; 
  }

  .grid-item .img {
    width:auto;
  }

  .ctnr p {
    font-size:16px;
  }
  
  .forms {
    font-size:16px;
  }

  .submit {
    font-size:15px;
  }

  .form-container .btn {
    margin-top:1em;
  }

  .input.forms.input {
    margin-bottom:1em;
  }

  .splash-txt {
    font-size:2em;
  }

  .splash-txt-small {
    font-size:16px;
  }

  .btn {
    font-size:.8em;
  }

  .splash-txt-ctnr {
    top:15%;
  }

  #modal-photo {
    width:100%;
    top:50%;
  }

  #modal {
    /* width:100%; */
    /* top:50%; */
  }

  .navbar img {
    height: 40px;
    padding-top: 13px;
    padding-bottom: 8px;
  }

  .nav-item {
    padding-top: 22px;
  }

  .nav-item i {
    font-size: 23px;
  }

}
@media (max-width:700px) {
  .grid-item {
    width:40%;
  }

}
@media (max-width:600px) {
  .thumbnails-bar::-webkit-scrollbar {
    height:8px;
  }

  .about img {
    display:block;
    float:unset;
    margin:0 auto;
    margin-top:.6em;
  }

  .mobile-only-breakpoint {
    display:block;
  }
  
  .grid-container {
    
    text-align:center;
  }
  
  .grid-item {
    width:100%;
    padding-right:0;
  }

  .ctnr h1,
  .ctnr h3 {
    text-align:center;
  }

  .splash-txt-ctnr {
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 1em;
    background: rgba(0,0,0,.4);
    top:unset !important;
  }

  .splash-txt {
    padding-bottom: 0.5em;
  }

  .splash-txt-small {
    padding-right: 0;
  }

  .splash-ctnr a {
    display:none;
  }

  .mute-btn {
    font-size:.9em;
  }

}
@media(max-width:500px) {
  .modal-x i {
    font-size:24px;
  }
  .thumbnails-holder {
    /* width:85px; */
  }
  .content {
    padding-left:2em;
    padding-right:2em;
  }

  .navbar img {
    height: 36px;
    padding-top: 12px;
    padding-bottom: 7px;
  }

  .nav-item {
    padding-top: 20px;
    padding-left: 1em;
  }

  .nav-item i {
    font-size: 21px;
  }

  .input.forms.input {
    margin-right:0;
  }

  .splash-txt-ctnr {
    padding-top: .5em;
    background: rgba(0,0,0,.4);
  }

  .splash-txt {
    padding-bottom: 0.25em;
    font-size:1.5em;
  }

  .splash-txt-small {
    padding-right:0;
    font-size:14px;
  }

  .splash-ctnr a {
    display:none;
  }

}
@media (max-width:400px) {
  .arrow {
    font-size:1.25rem;
  }
  .thumbnails-holder {
    width:70px;
  }
  .photo-thumbnail-padded {
    margin-right:.4rem;
  }
  .thumbnails-bar::-webkit-scrollbar {
    width: 8px;
    height:7px;
  }

  .ctnr {
    padding-top:1.5em;
  }

  .ctnr h1 {
    font-size:1.6em;
  }

  .ctnr h3 {
    font-size:1.2em;
  }

  .ctnr p {
    font-size:14px;
  }

  .forms {
    font-size:15px;
  }

  .submit {
    font-size:14px;
  }
  
  .content {
    padding-left:1.5em;
    padding-right:1.5em;
  }

  .about img {
    width:140px;
  }

  .work .project-title {
    font-size:.95rem;
  }

  .work .project-role {
    margin-top:.4em;
    font-size:.87rem;
  }

  .submit {
    margin:0 auto;
  }

  .form-container .btn {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }
  
  .form-container textarea {
    margin-bottom:2em !important;
  }

  .ctnr-footer {
    font-size:13px;
  }
  .footer-2 {
    font-size:11px;
  }

  .nav-left {
    padding-left:8%;
  }

  .nav-right {
    padding-right:8%;
  }
  
  .navbar img {
    height: 34px;
    padding-top: 11px;
    padding-bottom: 7px;
  }

  .nav-item {
    padding-top: 20px;
    padding-left: 1.2em;
  }

  .nav-item i {
    font-size: 18px;
  }

  .splash-txt-ctnr {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: .5em;
    background: rgba(0,0,0,.4);
  }

  .splash-ctnr {
    transform:translateY(-4px);
  }

  .splash-txt {
    padding-bottom: 0.33em;
    font-size:1.4em;
  }

  .splash-txt-small {
    padding-right: 0% !important;
    padding-right: 0;
    padding-bottom:.7em;
  }

  .splash-ctnr a {
    display:none;
  }

  #header-video {
    transform:translateY(4px);
  }

  .mute-btn {
    top:4px;
  }


}  
@media (max-width:360px) {
  .content {
    padding-left:1em;
    padding-right:1em;
  }
}
@media screen and (orientation: landscape) {
  #modal-photo {
    width:70%;
    /* top:55%; */
  }

  #modal {
    /* width:70%; */
  }
}
.ctnr-footer {
  padding:none !important;
}